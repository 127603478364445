:root {
  --space-column-xxs: 0.25em;
  --space-column-xs: 0.5em;
  --space-column-sm: 1em;
  --space-column-md: 1.5em;
  --space-column-lg: 2em;
  --space-column-xl: 3em;
  --space-column-xxl: 4em;
  --space-column-huge: 6em;
  --space-column-giant: 8em;

  --space-inset-xxs: 0.25em;
  --space-inset-xs: 0.5em;
  --space-inset-sm: 1em;
  --space-inset-md: 1.5em;
  --space-inset-lg: 2em;
  --space-inset-xl: 3em;
  --space-inset-xxl: 4em;

  --space-line-xxs: 0.25em;
  --space-line-xs: 0.5em;
  --space-line-sm: 1em;
  --space-line-md: 1.5em;
  --space-line-lg: 2em;
  --space-line-xl: 3em;
  --space-line-xxl: 4em;

  --space-squish-xxs: 0.25em;
  --space-squish-xs: 0.5em;
  --space-squish-sm: 1em;
  --space-squish-md: 1.5em;
  --space-squish-lg: 2em;
}
