/**
 * generic/_main.scss
 */

/* ==========================================================================
   Imports
   ========================================================================== */

// @import "";

.hide {
  display: none;
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
}
