.input {
  @include font-sizes;
  border: 0 none;
  outline:0 none;
  transition: 0.3s box-shadow linear;
  margin-bottom: var(--space-column-xxs);

  &.-text {
    padding: var(--space-inset-xs);
    border-radius: var(--border-radius-sm);
    box-shadow: var(--brand-neutral-50) 0 0 0 1px inset;

    &:focus {
      box-shadow: var(--brand-neutral-80) 0 0 0 1px inset;
    }

    &::placeholder {
      color: var(--brand-neutral-80);
      opacity: 0.33;
    }
    &.-success {
      box-shadow: var(--brand-denotive-success) 0 0 0 1px inset;
    }

    &.-error {
      box-shadow: var(--brand-denotive-error) 0 0 0 1px inset;
    }

    &.-warning {
      box-shadow: var(--brand-denotive-warning) 0 0 0 1px inset;
    }

    &.-disabled, &:disabled {
      pointer-events: none;
      color: var(--brand-neutral-50);
      background-color: var(--brand-neutral-20);
      box-shadow: var(--brand-neutral-50) 0 0 0 1px inset;
    }
  }
}

.checkbox {
  &.-default {
    margin-bottom: var(--space-inset-sm)
  }
  &.-switch {
    height: 0;
    width: 0;
    visibility: hidden;

    &:checked + .label {
      background: var(--brand-color-primary-medium);
    }

    &:checked + .label:after {
      left: calc(100% - 3px);
      transform: translateX(-100%);
    }
  }
}
