/**
 * tools/_main.scss
 */

/* ==========================================================================
   Imports
   ========================================================================== */

@mixin font-sizes {
  $sizes: xxs, xs, sm, md, lg, xl, xxl, huge, giant;
  @each $size in $sizes {
    &.-#{$size} {
      font-size: var(--brand-font-size-#{$size});
    }
  }
}

$browser-context: 16;

@function em($pixels, $context: $browser-context) {
  @if (unitless($pixels)) {
    $pixels: $pixels * 1px;
  }

  @if (unitless($context)) {
    $context: $context * 1px;
  }

  @return $pixels / $context * 1em;
}
