:root {
  --border-radius-none: 0;
  --border-radius-xs: 0.125em;
  --border-radius-sm: 0.25em;
  --border-radius-md: 0.5em;
  --border-radius-lg: 1em;
  --border-radius-pill: 50%;

  --border-width-none: 0;
  --border-width-hairline: 0.01875em;
  --border-width-thin: 0.0625em;
  --border-width-thick: 0.125em;
  --border-width-heavy: 0.25em;
}
