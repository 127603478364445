.label {
  display: flex;
  flex-direction: column;

  span {
    @include font-sizes;
    display: block;
    margin-bottom: var(--space-column-xxs);
  }
  margin-bottom: var(--space-column-xs);

  &.-switch {
    cursor: pointer;
    text-indent: -9999px;
    width: 44px;
    height: 24px;
    background: var(--brand-neutral-50);
    display: block;
    border-radius: var(--border-radius-lg);
    position: relative;
  }

  &.-switch:after {
    content: '';
    position: absolute;
    top: 3px;
    left: 3px;
    width: 18px;
    height: 18px;
    background: var(--brand-basic-white);
    border-radius: 90px;
    transition: 0.3s;
  }

  &.-switch:active:after {
    width: 24px;
  }
}
