.left  {
  width: 100%;
  grid-area: left;
  background-color: var(--brand-neutral-20);

  @media screen and (min-width: 768px) {
    max-width: 450px;
  }

  /* FIXME: This should be made more maintanableable */
  @media (prefers-color-scheme: dark) {
    background-color: var(--brand-neutral-80);
  }
}

.navigation {
  display: flex;
  max-width: 90%;
  margin: 0 auto;
  align-items: center;
  margin-bottom: var(--space-inset-md);

  .far {
    color: var(--brand-color-primary-medium);
    margin-left: var(--space-column-sm);
    @include font-sizes;
  }

}

.steps-navigation {
  padding-bottom: var(--space-inset-xl);
}

.sidebar {
  @media screen and (min-width: 768px) {
    position: fixed;
    width:inherit;
    max-width:inherit;
  }
}

.product {
  padding-bottom: var(--space-inset-lg);
  padding-left: var(--space-inset-md);
  padding-right: var(--space-inset-md);

  .product-details {
    display: flex;
    align-items: center;
    flex-direction: column;

    @media screen and (min-width: 768px) {
      align-items: flex-start;
    }
  }

  .-proposal-text-call {
    color: var(--brand-neutral-60);
    /* FIXME: This should be made more maintanableable */
      @media (prefers-color-scheme: dark) {
      color: var(--brand-neutral-10);
    }
  }

  .product-details .title {
    /* FIXME: This should be made more maintanableable */
      @media (prefers-color-scheme: dark) {
        color: var(--brand-neutral-10);
      }
  }

  .product-details .image {
    flex-grow: 1;
    flex-basis: 145%;
    margin-right: var(--space-column-sm);
    margin-bottom: var(--space-column-sm);
    align-self: center;
  }

  @media screen and (min-width: 768px) {
    .product-details .title {
      margin-bottom: var(--space-column-md);
    }
  }

  .product-advantages-list {
    display: none;
    visibility: hidden;
  }


  @media screen and (min-width: 768px) {
    .product-advantages-list {
      display: block;
      visibility: visible;
      list-style: none;
      padding: 0;
    }

    .product-advantages-item {
      display: flex;
      align-items: baseline;
      justify-content: baseline;
      align-content: center;
      margin-bottom: var(--space-column-md);

      > span {
        display: block;
        font-size: var(--brand-font-size-md);
        min-width: 24px;
        text-align: center;
      }

      /* FIXME: This should be made more maintanableable */
      @media (prefers-color-scheme: dark) {
        color: var(--brand-neutral-10);
      }
    }

    .product-advantages-item-info {
      display: flex;
      flex-direction: column;
      margin-left: var(--space-column-md);
      :first-child {
        margin-bottom: var(--space-column-xs);
      }
      :last-child {
        font-weight: 600;
      }
    }
  }
}

.steps {
  grid-area: right;
  background-color: var(--brand-neutral-10);
  padding: var(--space-inset-md);
  min-height: calc(100vh - 166px);

  @media screen and (min-width: 768px) {
    padding: var(--space-inset-xl);
  }

  .step {
    margin-bottom: var(--space-column-sm);

    &.-inactive {
      opacity: none;
      visibility: hidden;
      display: none;
    }

    @media screen and (min-width: 768px) {
      max-width: 30vw;
    }
  }

  .step > .title {
    margin-bottom: var(--space-column-md);

    .fas {
      @include font-sizes;
      margin-right: var(--space-column-sm);
    }
  }
}

.steps .form {
  display: flex;
  flex-direction: column;
  margin-bottom: var(--space-column-md);

  @media screen and (min-width: 768px) {
    .button {
      max-width: 50%;
    }
  }

}

.steps .group {
  display: flex;
  flex-direction: column;

  .-terms {
    margin-left: var(--space-column-xs);
  }

  &.-column {
    flex-direction: row;
    align-items: center;

    &.-switch {
      .label {
          max-width: 44px;
          min-width: 44px;
      }
    }

    &.-inputs {
        justify-content: space-between;

        .label {
          width: 48%;
        }
    }

    .details {
      margin-left: var(--space-column-md);
    }

    .details .title {
      margin-bottom: 0;
      font-weight: 400;
    }
  }

  .anchorLink.-external {
    color: var(--brand-denotive-info-blue);
    font-weight: 400;
    transition: 0.3s color linear;
    &:hover {
      color: var(--brand-denotive-success);
    }
  }
}

.footer {
  grid-area: footer;
  bottom: 0;
}
