.select {
  @include font-sizes;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;

  border: 0 none;
  outline: 0 none;

  transition: 0.3s box-shadow linear;
  margin-bottom: var(--space-column-xxs);
  padding: var(--space-inset-xs);
  border-radius: var(--border-radius-sm);
  box-shadow: var(--brand-neutral-50) 0 0 0 1px inset;

  &::-ms-expand {
    display: none;
  }

  &:focus {
    box-shadow: var(--brand-neutral-80) 0 0 0 1px inset;
  }

  &::placeholder {
    color: var(--brand-neutral-80);
    opacity: 0.33;
  }
  &.-success {
    box-shadow: var(--brand-denotive-success) 0 0 0 1px inset;
  }

  &.-error {
    box-shadow: var(--brand-denotive-error) 0 0 0 1px inset;
  }

  &.-warning {
    box-shadow: var(--brand-denotive-warning) 0 0 0 1px inset;
  }

  &.-disabled, &:disabled {
    pointer-events: none;
    color: var(--brand-neutral-50);
    background-color: var(--brand-neutral-20);
    box-shadow: var(--brand-neutral-50) 0 0 0 1px inset;
  }

  background-image: url('../../../../../public/assets/images/vectors/select-down.svg');
  background-position: calc(100% - 12px) calc(16px);
  background-size: 12px 6px;
  background-repeat: no-repeat;
  &:focus {
    background-image: url('../../../../../public/assets/images/vectors/select-up.svg');
  }
}
