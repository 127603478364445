:root {
  --brand-color-primary-lighttest: #4DFFA0;
  --brand-color-primary-light: #25F787;
  --brand-color-primary-medium: #009E3D;
  --brand-color-primary-dark: #00C75D;
  --brand-color-primary-darkest: #009445;
  --brand-color-secondary-lighttest: #94E8F7;
  --brand-color-secondary-light: #79DFF2;
  --brand-color-secondary-medium: #5CCFE5;
  --brand-color-secondary-dark: #3DB5CC;
  --brand-color-secondary-darkest: #1F8599;
  --brand-color-text-lighttest: #FFFFFF;
  --brand-color-text-light: #EBE9E8;
  --brand-color-text-medium: #A6A6AB;
  --brand-color-text-dark: #54545C;
  --brand-color-text-darkest: #35353B;

  --brand-basic-white: #FFFFFF;

  --brand-neutral-10: #F5F7F7;
  --brand-neutral-20: #EAEEEE;
  --brand-neutral-30: #D5DDDD;
  --brand-neutral-80: #262B2B;
  --brand-neutral-60: #718080;
  --brand-neutral-50: #ACBBBB;

  --brand-denotive-success: #46B655;
  --brand-denotive-error:#D21C1C;
  --brand-denotive-warning:#F9971E;
  --brand-denotive-info-blue:#0172CB;

  --brand-color-dot: #00E169;
}
