/**
 * components/_main.scss
 */

/* ==========================================================================
   Atoms
========================================================================== */

@import "atoms/logo";
@import "atoms/link";
@import "atoms/paragraph";
@import "atoms/button";
@import "atoms/titles";
@import "atoms/inputs";
@import "atoms/selects";
@import "atoms/label";

/* ==========================================================================
Molecules
========================================================================== */

@import "molecules/header";

.-success {
  color: var(--brand-denotive-success);
}

.-error {
  color: var(--brand-denotive-error);
}

.-warning {
  color: var(--brand-denotive-warning);
}
