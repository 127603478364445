.button {
  @include font-sizes;
  background-color: var(--brand-color-primary-medium);
  color: var(--brand-neutral-10);
  border-radius: var(--border-radius-sm);
  border-width: var(--border-width-none);
  padding: var(--space-inset-sm) var(--space-inset-lg);
  display: flex;
  align-items: center;
  justify-content: center;

  font-weight: 700;
  text-transform: uppercase;
  cursor: pointer;
  transition: 0.3s background-color linear;

  &:hover {
    background-color: var(--brand-color-primary-dark);
  }

  &.-disabled {
    pointer-events: none;
    opacity: 33%;
  }

  &.-outline {
    background-color: var(--brand-basic-white);
    color: var(--brand-neutral-80);
    border-color: var(--brand-neutral-80);
    border-width: var(--border-width-thin);
  }

  &.-icon {

    &:hover {
      .fas {
        transform: rotate(45deg);
      }
    }

    &.-icon-right {
      .fas {
        margin-left: var(--space-column-xs);
        transition: 0.3s transform cubic-bezier(0.645, 0.045, 0.355, 1);
      }
    }
  }

  &.-icon-left {
    .fas {
      margin-right: var(--space-column-xs);
    }
  }
}
