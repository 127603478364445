.paragraph {
  @include font-sizes;
  font-weight: 400;
  line-height: 24px;
  margin-top:0;
  margin-bottom:var(--space-column-sm);
  margin-left:0;
  margin-right:0;

  &.-boxed {
    background-color: var(--brand-neutral-20);
    padding: var(--space-inset-md);
    border-radius: var(--border-radius-xs);
  }
}
