.page-container {
  display: grid;
  grid-template-rows: 1fr 80px;
  grid-auto-columns: 1fr;
  grid-template-areas:
    "Main"
    "Footer";
    position: relative;
    height: 100vh;

    @media (prefers-color-scheme: dark) {
      background-color: var(--brand-neutral-80);
      color: var(--brand-neutral-10);

      .anchorLink {
        color: var(--brand-neutral-10);
      }
    }
  }

.main {
  grid-area: Main;
  display: flex;
  flex-direction: column;
  padding: var(--space-inset-lg);

  &.-row {
    flex-direction: column;
  }

  @media screen and (min-width: 768px) {
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  > .column {
    min-height: 25vh;
    @media screen and (min-width: 768px) {
      width: 50%;
    }
    position: relative;

    > .title {
      margin-bottom: var(--space-column-md);

      @media screen and (min-width: 768px) {
        position: absolute;
        top: -20vh;
      }
    }

    .row {
      .title {
        margin-bottom: var(--space-column-xs);
      }

      .button {
        display: inline-block;
      }

      .paragraph {
        max-width: 80%;
      }
    }
  }

  > .column > .row {
    margin-bottom: var(--space-column-xl);
  }

  @media screen and (min-width: 768px) {
    width: 1081px;
    margin: 0 auto;
  }
}

.footer { grid-area: Footer; bottom: 0; }

