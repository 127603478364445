.logo {
  width: Min(127px, 100%);
  .type {
    fill: #4A4B4C;
  }

  .dot {
    fill:var(--brand-color-dot);
  }

  /* FIXME: This should be made more maintanableable */
  @media (prefers-color-scheme: dark) {
    .type {
      fill: var(--brand-basic-white);
    }
  }

}
