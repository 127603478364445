@charset "UTF-8";

/** critical.scss */

/* ==========================================================================
   Settings
   ========================================================================== */

/** Variables. */
@import "settings/main";


/* ==========================================================================
   Tools
   ========================================================================== */

/** Functions, mixins etc. */
@import "tools/main";


/* ==========================================================================
   Vendor
   ========================================================================== */

/** Third party components. */
@import "vendor/critical";


/* ==========================================================================
   Generic
   ========================================================================== */

/** Low-specificity, far-reaching rulesets. */
@import "generic/critical";


/* ==========================================================================
   Base
   ========================================================================== */

/** Unclassed HTML elements. */
@import "base/critical";


/* ==========================================================================
   Objects
   ========================================================================== */

/** Design-free objects, abstractions and patterns. */
@import "objects/critical";


/* ==========================================================================
   Components
   ========================================================================== */

/** Components and widgets. */
@import "components/critical";


/* ==========================================================================
   Trumps
   ========================================================================== */

/** Helper classes and overrides. */
@import "trumps/critical";
