.result-container {
  @media screen and (min-width: 768px) {
    max-width: 30vw;
  }

  padding-top: var(--space-inset-lg);
  padding-bottom: var(--space-inset-lg);
  padding-left: var(--space-inset-xs);
  padding-right: var(--space-inset-xs);

  flex-direction: column;
  align-items: center;

  .title {
    margin-bottom: var(--space-column-xs);
  }

  .paragraph {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    @media (prefers-color-scheme: dark) {
      color: var(--brand-neutral-80);
    }

    &.-no-flex {
      display: block;
    }
  }

  .actions {
    display: flex;
    text-align: center;
    flex-direction: column;
    justify-content: center;
    padding: var(--space-inset-xl) var(--space-inset-md);
    background-color: var(--brand-basic-white);
    border-radius: var(--border-radius-lg);
  }

  > .paragraph {
    @media (prefers-color-scheme: dark) {
      color: var(--brand-neutral-70) !important;
    }
  }
}
